select.form,
input[type=text].form,
textarea.form,
form select,
form input[type=text],
form input[type=password],
form input[type=email],
form textarea,
#contact-email-container select,
#contact-email-container input[type=text],
#contact-email-container input[type=password],
#contact-email-container input[type=email],
#contact-email-container textarea {
	background: #ffffff; /* Old browsers */
	border: 1px solid var(--color-dark);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: var(--border-radius-small);
	color: var(--color-dark);
	display: inline-block;
	font-family: var(--font-family-primary);
	font-size: var(--font-size-base);
	margin: 0px;
	padding: 1.5rem 3.0rem;
	width: 100%;
}

form label,
#contact-email-container label {
	display: block;
	font-size: var(--font-size-base);
	font-weight: bold;
	letter-spacing: 0px;
	padding-bottom: 5px;
}

form label.inline,
#contact-email-container label.inline {
	display: inline-block;
}

form label.error,
#contact-email-container label.error {
	color: red;
	display: block;
}

div.input,
div.input-group {
	margin: 2rem 0;
}

.btn.submit {
	text-align: right;
}

.email-form .helptext {
	font-size: 0.8em;
	color: #710202;
}

.email-form #server-response {
	background: #eee;
	border: #ddd 1px solid;
	border-radius: 3px;
	padding: 1em;
}