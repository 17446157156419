
:root {
	--school-code: 'dsbn';
	--width-logo: 120px;
	--height-logo: 120px;
	--height-navigation: 350px;
	--height-header: 120px;

	--padding-gap: 4rem;
	--padding-section-spacing: 6rem;

	--border-radius: 15px;
	--border-radius-small: 10px;

	--width-wrapper-main: 1200px;
	--width-wrapper-inside: 1100px;
	--width-wrapper-small: 1000px;
	--width-wrapper-medium: 1350px;
	--width-wrapper-large: 1500px;

	--font-family-primary: 'Open Sans', sans-serif;
	--font-family-secondary: 'Poppins', sans-serif;
	--font-family-heading: 'Open Sans', sans-serif;
	--font-family-menu: 'Open Sans', sans-serif;
	--font-family-footer-body: 'Open Sans', sans-serif;

	--font-size-base: 16px;
	--font-size-heading-one: 4.0em;
	--font-size-heading-two: 2.5em;
	--font-size-heading-three: 1.5em;
	--font-size-heading-four: 1.2em;
	--font-size-heading-five: 1.0em;
	--font-size-paragraph-bg: 1.2em;

	--color-primary: #1e355e;
	--color-secondary: #1e355e;
	--color-overlay: rgba(171,29,55,.70);
	--color-footer: #1e355e;
	--color-dark: #000;
	--color-light: #ffffff;
	--color-gray: #eee;

	--color-heading: var(--color-dark);
	--color-body: var(--color-dark);
	--color-accent: var(--color-secondary);

	--color-footer-menu-text: var(--color-light);
	--color-footer-menu-text-hover: var(--color-light);
}