#cta-container {
	// background-image: url( '../images/cta-background.png' );
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: var(--padding-section-spacing) 0;
	text-align: center;
}

#cta-container .heading.one,
#cta-container .heading.two {
	margin: 10px 0;
}

#cta-container .wrapper {
	text-align: center;
}

#cta-container .cta-button {
	margin: 2rem 0;
}

#cta-container .btn {
	margin: 0 20px;
}

#cta-container p {
	max-width: 70%;
	margin: 16px auto;
}

#cta-container hr {
	width: 10%;
	margin: 20px auto;
}


/* ALTERNATE CTA */


.cta-container-alt {
	background-color: var(--color-secondary);
	color: #ffffff;
	text-align: left;
	width: 100%;
}

.cta-flex-container-alt {
	// background-image: url( '../images/cta-watermark.png' );
	background-size: auto 100%;
	background-position: calc(50% - 100px) center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: var(--padding-section-spacing);
}

.cta-flex-container-alt .cta-item {
	padding: 0;
}

.cta-container-alt .heading.two,
.cta-container-alt .heading.three a,
.cta-container-alt .heading.four a {
	color: #ffffff;
}

.cta-container-alt .contact-flex-item i {
	color: var(--color-accent);
}

.cta-container-alt a:not(.btn) {
	color: inherit;
}

.cta-container-alt .cta-item.contact span {
	font-size: var(--font-size-paragraph-bg);
	margin: 0 20px 0 0;
}