.slideshow {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.slides {
	display: flex;
	transition: transition 0.5s ease-in-out;
	width: 100%;
	height: 100%;
}

.slide {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex: 0 0 100%; /* Each slide takes up 100% of the available width */
	height: 100%;
}

.slide-transition {
	transition: margin-left 0.5s ease-in-out;
}

.slide-content {
	background-color: lightblue;
	width: 100%;
	height: 100%;
}

.slide-flex {
	flex-direction: row;
	align-items: center;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: 850px;
	margin: 0 auto;
	padding: 0 20px;
}

.slide-text {
	text-align: left;
}

.slide-image {
	text-align: center;
	max-height: 100%;
	padding: 4rem 0;
}

.slide-image img {
	max-width: 100%;
	max-height: 100%;
	height: auto;
}

.arrow-button {
	background-color: transparent;
	border: none;
	color: white;
	cursor: pointer;
	font-size: 300%;
	position: absolute;
}

#prevBtn {
	left: 20px;
}

#nextBtn {
	right: 20px;
}

#downBtn {
	background-image: url( ../images/top.png );
	background-position: center;
	background-repeat: no-repeat;
	background-size: 30px auto;
	bottom: 20px;
	cursor:pointer;
	right: 0;
	left: 0;
	margin: 0 auto;
	opacity: 0;
	position: absolute;
	transform: rotate(180deg); /* Flip the image */
  height: 45px;
  width: 45px;
	visibility: hidden;
	z-index: 100;
  -webkit-animation: bob_up_down 2s ease-in-out infinite;
  -moz-animation:    bob_up_down 2s ease-in-out infinite;
  animation:         bob_up_down 2s ease-in-out infinite;
}

@keyframes bob_up_down {
  0%   { transform: translateY(0) rotate(180deg); }
  50%  { transform: translateY(-20px) rotate(180deg); }
  100% { transform: translateY(0) rotate(180deg); }
}

#downBtn.active {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-height: 800px) {
  #downBtn {
    position: fixed;
    bottom: 20px;
  }
}