footer {
	background-color: var( --color-footer );
	color: var( --color-light );
	font-size: 90%;
	font-family: var(--font-family-footer-body);
	line-height: 1.4em;
	padding: var( --padding-gap ) 0;
	margin-bottom: 14rem;
}

.footer-container {
	align-items: flex-start;
	justify-content: space-around;
	display: flex;
	gap: var( --padding-gap );
	// grid-template-columns: 200px 1fr 1fr 1fr;
}

footer a {
	color: var( --color-light );

	&:hover {
		color: var( --color-gray );
	}
}

.footer-redefining-logo {
	display: block;
	max-width: 225px;
}

.footer-logo {
	display: block;
	background-color: #2359a8;
	padding: 17px 17px 37px;
	position: relative;
	width: 120px;

	svg {
		display: block;
		height: 104px;
		fill: #fff;
		margin: 0 auto;
		width: 77px;
	}

	.blocks {
		bottom: 0;
		display: grid;
		height: 20px;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		left: 0;
		position: absolute;
		width: 100%;

		.block:first-of-type {
			background: #a3cf60
		}

		.block:nth-of-type(2) {
			background: #ffc843
		}

		.block:nth-of-type(3) {
			background: #2574bb
		}

		.block:nth-of-type(4) {
			background: #732a81
		}

		.block:nth-of-type(5) {
			background: #f78f1e
		}

		.block:nth-of-type(6) {
			background: #d70036
		}

		.block:hover {
			background: #2359a8
		}
	}
}

ul.footer-menu {
	border: 0px none;
	display: block;
	height: 100%;
	list-style: none;
	margin: 0px;
	padding: 0px;
	position: relative;
}

ul.footer-menu.uppercase li a {
	text-transform: uppercase;
}

ul.footer-menu li {
	background: none;
	display: block;
	height: 100%;
	list-style: none;
	margin: 0.5em 0px;
	padding: 0px;
	position: relative;
	text-align: left;
}

ul.footer-menu li a {
	color: var(--color-footer-menu-text);
	display: block;
	float: none !important; /*For Opera*/
	float: left; /*For IE*/
	font-family: var(--font-family-menu);
	font-size: 100%;
	font-weight: normal;
	height: 100%;
	// padding: 0px 20px;
	text-decoration: uppercase;
	text-transform: none;
	z-index: 99;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
