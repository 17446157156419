:root {
	--grid-gap: 6rem;
	--grid-gap-tablet: 4rem;
	--grid-gap-mobile: 2rem;
}

.grid {
	display: grid;
	display: -ms-grid;
	grid-template-columns: 1fr 1fr; /*base properties to be overwritten*/
	-ms-grid-columns:1fr var(--grid-gap) 1fr; /*ie base properties to be overwritten*/
	grid-gap: var(--grid-gap);
	align-items: center;
	margin: 0;
}

.grid.top-align,
.grid.left-image.top-align,
.grid.right-image.top-align {
	align-items: start!important;
}

.grid.bottom-align,
.grid.left-image.bottom-align,
.grid.right-image.bottom-align {
	align-items: flex-end!important;
}

.grid.two {
	grid-template-columns: 1fr 1fr;
	-ms-grid-columns:1fr var(--grid-gap) 1fr;
	grid-gap: var(--grid-gap);
}

.grid.two.wide {
	grid-template-columns: 1fr 1fr;
	-ms-grid-columns:1fr var(--grid-gap) 1fr;
	grid-gap: var(--grid-gap);
}

.grid.two.sidebar {
	grid-template-columns: 2fr 1fr;
	-ms-grid-columns: 2fr var(--grid-gap) 1fr;
	grid-gap: var(--grid-gap);
}

.grid.two.team {
	grid-template-columns: 1fr 2fr;
	-ms-grid-columns: 1fr var(--grid-gap) 2fr;
	grid-gap: var(--grid-gap);
}

.grid.three {
	grid-template-columns: 1fr 1fr 1fr;
	-ms-grid-columns:1fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
	grid-gap: var(--grid-gap);
}

.grid.four {
	grid-template-columns: 1fr 1fr 1fr 1fr; /*base properties to be overwritten*/
	-ms-grid-columns:1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr; /*ie base properties to be overwritten*/
	grid-gap: var(--grid-gap);
}

.grid.five {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /*base properties to be overwritten*/
	-ms-grid-columns:1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr; /*ie base properties to be overwritten*/
	grid-gap: var(--grid-gap);
}

.grid.six {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /*base properties to be overwritten*/
	-ms-grid-columns:1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr var(--grid-gap) 1fr; /*ie base properties to be overwritten*/
	grid-gap: var(--grid-gap);
}

.grid.two.right-image {
	grid-template-columns: 1fr 600px;
	-ms-grid-columns:1fr var(--grid-gap) 600px;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.two.right-image.hero-image {
	grid-template-columns: 1fr 60%;
	-ms-grid-columns:1fr 0rem 60%;
	grid-gap: 0;
	align-items: start;
}

.grid.two.left-image {
	grid-template-columns: 600px 1fr;
	-ms-grid-columns:600px var(--grid-gap) 1fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.three.right-image {
	grid-template-columns: 1fr 1fr 600px;
	-ms-grid-columns:1fr var(--grid-gap) 1fr var(--grid-gap) 600px;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.three.left-image {
	grid-template-columns: 600px 1fr 1fr;
	-ms-grid-columns:600px var(--grid-gap) 1fr var(--grid-gap) 1fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.two.right-image.large {
	grid-template-columns: 1fr 2fr;
	-ms-grid-columns: 1fr var(--grid-gap) 2fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.two.left-image.large {
	grid-template-columns: 2fr 1fr;
	-ms-grid-columns: 2fr var(--grid-gap) 1fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.three.right-image.large {
	grid-template-columns: 1fr 1fr 2fr;
	-ms-grid-columns: 1fr var(--grid-gap) 1fr var(--grid-gap) 2fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid.three.left-image.large {
	grid-template-columns: 2fr 1fr 1fr;
	-ms-grid-columns: 2fr var(--grid-gap) 1fr var(--grid-gap) 1fr;
	grid-gap: 0 var(--grid-gap);
	align-items: center;
}

.grid-item {
	position: relative;
}

.grid-item.span-two {
	grid-column: span 2;
}

.grid-item.span-three {
	grid-column: span 3;
}

.grid-item.span-four {
	grid-column: span 4;
}

.grid-item.span-five {
	grid-column: span 5;
}

.grid-item.no-gap {
	margin: calc(var(--grid-gap) * -1);
}

.grid-item.no-gap-top {
	margin-top: calc(var(--grid-gap) * -1);
}

.grid-item.no-gap-bottom {
	margin-bottom: calc(var(--grid-gap) * -1);
}

.grid.two > .grid-item:nth-of-type(1),
.grid.two > div:nth-of-type(1) {
	-ms-grid-column: 1;
	-ms-grid-row: 1;
}

.grid.two > .grid-item:nth-of-type(2),
.grid.two > div:nth-of-type(2) {
	-ms-grid-column: 3;
	-ms-grid-row: 1;
}

.grid.two > .grid-item:nth-of-type(3),
.grid.two > div:nth-of-type(3) {
	-ms-grid-column: 1;
	-ms-grid-row: 2;
}

.grid.two > .grid-item:nth-of-type(4),
.grid.two > div:nth-of-type(4) {
	-ms-grid-column: 3;
	-ms-grid-row: 2;
}

.grid.two > .grid-item:nth-of-type(5),
.grid.two > div:nth-of-type(5) {
	-ms-grid-column: 1;
	-ms-grid-row: 3;
}

.grid.two > .grid-item:nth-of-type(6),
.grid.two > div:nth-of-type(6) {
	-ms-grid-column: 3;
	-ms-grid-row: 3;
}

/* if there is 3 columns*/

.grid.three > .grid-item:nth-of-type(1),
.grid.three > div:nth-of-type(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(2),
.grid.three > div:nth-of-type(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(3),
.grid.three > div:nth-of-type(3) {
	-ms-grid-row: 1;
	-ms-grid-column: 5;
}

.grid.three > .grid-item:nth-of-type(4),
.grid.three > div:nth-of-type(4) {
	-ms-grid-row: 2;
	-ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(5),
.grid.three > div:nth-of-type(5) {
	-ms-grid-row: 2;
	-ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(6),
.grid.three > div:nth-of-type(6) {
	-ms-grid-row: 2;
	-ms-grid-column: 5;
}

.grid.three > .grid-item:nth-of-type(7),
.grid.three > div:nth-of-type(7) {
	-ms-grid-row: 3;
	-ms-grid-column: 1;
}

.grid.three > .grid-item:nth-of-type(8),
.grid.three > div:nth-of-type(8) {
	-ms-grid-row: 3;
	-ms-grid-column: 3;
}

.grid.three > .grid-item:nth-of-type(9),
.grid.three > div:nth-of-type(9) {
	-ms-grid-row: 3;
	-ms-grid-column: 5;
}

/* if there is 4 columns*/

.grid.four > .grid-item:nth-of-type(1),
.grid.four > div:nth-of-type(1),
.grid.image-list.four > .grid-item:nth-of-type(1) {
	-ms-grid-column: 1;
	-ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(2)
.grid.four > div:nth-of-type(2),
.grid.image-list.four > .grid-item:nth-of-type(2) {
	-ms-grid-column: 3;
	-ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(3)
.grid.four > div:nth-of-type(3),
.grid.image-list.four > .grid-item:nth-of-type(3) {
	-ms-grid-column: 5;
	-ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(4)
.grid.four > div:nth-of-type(4),
.grid.image-list.four > .grid-item:nth-of-type(4) {
	-ms-grid-column: 7;
	-ms-grid-row: 1;
}

.grid.four > .grid-item:nth-of-type(5)
.grid.four > div:nth-of-type(5),
.grid.image-list.four > .grid-item:nth-of-type(5) {
	-ms-grid-column: 1;
	-ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(6)
.grid.four > div:nth-of-type(6),
.grid.image-list.four > .grid-item:nth-of-type(6) {
	-ms-grid-column: 3;
	-ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(7)
.grid.four > div:nth-of-type(7),
.grid.image-list.four > .grid-item:nth-of-type(7) {
	-ms-grid-column: 5;
	-ms-grid-row: 2;
}

.grid.four > .grid-item:nth-of-type(8)
.grid.four > div:nth-of-type(8),
.grid.image-list.four > .grid-item:nth-of-type(8) {
	-ms-grid-column: 7;
	-ms-grid-row: 2;
}

/* if there is 6 columns*/

.grid.six > .grid-item:nth-of-type(1),
.grid.six > div:nth-of-type(1) {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
}

.grid.six > .grid-item:nth-of-type(2),
.grid.six > div:nth-of-type(2) {
	-ms-grid-row: 1;
	-ms-grid-column: 3;
}

.grid.six > .grid-item:nth-of-type(3),
.grid.six > div:nth-of-type(3) {
	-ms-grid-row: 1;
	-ms-grid-column: 5;
}

.grid.six > .grid-item:nth-of-type(4),
.grid.six > div:nth-of-type(4) {
	-ms-grid-row: 1;
	-ms-grid-column: 7;
}

.grid.six > .grid-item:nth-of-type(5),
.grid.six > div:nth-of-type(5) {
	-ms-grid-row: 1;
	-ms-grid-column: 9;
}

.grid.six > .grid-item:nth-of-type(6),
.grid.six > div:nth-of-type(6) {
	-ms-grid-row: 1;
	-ms-grid-column: 12;
}

.grid.six > .grid-item:nth-of-type(7),
.grid.six > div:nth-of-type(7) {
	-ms-grid-row: 2;
	-ms-grid-column: 1;
}

.grid.six > .grid-item:nth-of-type(8),
.grid.six > div:nth-of-type(8) {
	-ms-grid-row: 2;
	-ms-grid-column: 3;
}

.grid.six > .grid-item:nth-of-type(9),
.grid.six > div:nth-of-type(9) {
	-ms-grid-row: 2;
	-ms-grid-column: 5;
}

.grid.six > .grid-item:nth-of-type(10),
.grid.six > div:nth-of-type(10) {
	-ms-grid-row: 2;
	-ms-grid-column: 7;
}

.grid.six > .grid-item:nth-of-type(11),
.grid.six > div:nth-of-type(11) {
	-ms-grid-row: 2;
	-ms-grid-column: 9;
}

.grid.six > .grid-item:nth-of-type(12),
.grid.six > div:nth-of-type(12) {
	-ms-grid-row: 2;
	-ms-grid-column: 12;
}


/* MEDIA QUERIES */

@media all and (max-width: 992px) {

	.grid.two.right-image {
		grid-template-columns: 1fr 300px;
		-ms-grid-columns:1fr var(--grid-gap-tablet) 300px;
		grid-gap: 0 var(--grid-gap-tablet);
	}

	.grid.two.left-image {
		grid-template-columns: 300px 1fr;
		-ms-grid-columns: 300px var(--grid-gap-tablet) 1fr;
		grid-gap: 0 var(--grid-gap-tablet);
	}

	.grid.three.right-image {
		grid-template-columns: 1fr 1fr 300px;
		-ms-grid-columns:1fr var(--grid-gap-tablet) 1fr var(--grid-gap-tablet) 300px;
		grid-gap: 0 var(--grid-gap-tablet);
	}

	.grid.three.left-image {
		grid-template-columns: 300px 1fr 1fr;
		-ms-grid-columns: 300px var(--grid-gap-tablet) 1fr var(--grid-gap-tablet) 1fr;
		grid-gap: 0 var(--grid-gap-tablet);
	}

	.grid.four.footer-item-container,
	.grid.five.footer-item-container {
		grid-template-columns: 1fr 1fr;
		-ms-grid-columns: 1fr var(--grid-gap-tablet) 1fr;
		grid-gap: var(--grid-gap-tablet);
		// margin: var(--grid-gap-tablet) 0;
	}

}

@media all and (max-width: 768px) {

	.grid.two,
	.grid.two.sidebar,
	.grid.two.left-image,
	.grid.two.right-image,
	.grid.two.right-image.large,
	.grid.two.left-image.large,
	.grid.three,
	.grid.three.right-image,
	.grid.three.left-image,
	.grid.four,
	.grid.five,
	.grid.six {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-gap: var(--grid-gap-mobile);
		// margin: var(--grid-gap-mobile) 0;
	}

	.grid-item.no-gap {
		margin: calc(var(--grid-gap-mobile) * -1);
	}

	.grid-item.no-gap-top {
		margin-top: calc(var(--grid-gap-mobile) * -1);
	}

	.grid-item.no-gap-bottom {
		margin-bottom: calc(var(--grid-gap-mobile) * -1);
	}

	.strat-grid.grid.two.left-image {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-gap: var(--grid-gap-mobile);
		margin-bottom:  var(--grid-gap-mobile);
	}

	.grid.four.footer-item-container,
	.grid.five.footer-item-container {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
		grid-gap: var(--grid-gap-mobile);
	}


}