.btn {
	border: 1px solid var(--color-primary);
	background-color:  var(--color-primary);
	border-radius: var(--border-radius-small);
	color:  var( --color-light );
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-family-primary);
	font-size: var(--font-size-base);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0px;
	padding: 1.5rem 3rem;
	text-decoration: none;
	text-transform: none;
	-webkit-appearance: none;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.btn:hover {
	border-color: var(--color-secondary);
	background-color: var(--color-secondary);
	color: var(--color-light);
	text-decoration: none;
}

.btn.secondary {
	border-color: var(--color-secondary);
	background-color: var(--color-secondary);
	color: var(--color-light);
}

.btn.secondary:hover {
	border-color: var(--color-primary);
	background-color: var(--color-primary);
	color: var(--color-light);
}

.btn.ghost {
	border-color: var(--color-primary);
	background-color: transparent;
	color: var(--color-primary);
}

.btn.ghost:hover {
	border-color: var(--color-primary);
	background-color: var(--color-primary);
	color: var(--color-light);
}

.btn.secondary.ghost {
	border-color: var(--color-secondary);
	background-color: transparent;
	color: var(--color-secondary);
}

.btn.secondary.ghost:hover {
	border-color: var(--color-secondary);
	background-color: var(--color-secondary);
	color: var(--color-light);
}

.btn.white {
	border-color: var(--color-light);
	background-color: var(--color-light);
	color: var(--color-dark);
}

.btn.white:hover {
	border-color: var(--color-primary);
	background-color: var(--color-primary);
	color: var(--color-light);
}

.btn.white.ghost {
	border-color: var(--color-light);
	background-color: transparent;
	color: var(--color-light);
}

.btn.white.ghost:hover {
	border-color: var(--color-light);
	background-color: var(--color-light);
	color: var(--color-primary);
}

.btn.no-rounded {
	border-radius: 0;
}

.btn.no-rounded {
	border-radius: 0;
}

.btn-group {
	margin: 2rem 0;
}

.btn-group .btn {
	margin-right: 0.5rem;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.btn-group .btn:last-child {
	margin-right: 0;
}

.btn.watch {
	align-items: center;
	display: inline-flex;
	font-size: 1.2rem;
	justify-content: space-between;
	padding: 7px;
}

.btn.watch span.play {
	aspect-ratio: 1/1;
	background-color: #b5cddd;
	background-image: url( ../images/play.png );
	background-position: center;
	background-size: 30% auto;
	background-repeat: no-repeat;
	border-radius: 50%;
	margin-right: 7px;
	width: 40px;
	text-indent: -9999px;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.btn.watch:hover span.play {
	margin-left: 14px;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.btn.watch span.watch {
	text-align: center;
	padding: 0 2.5rem 0 2rem;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.btn.watch:hover span.watch {
	text-align: center;
	padding: 0 2.5rem 0 calc(2rem - 14px);
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
