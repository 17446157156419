@media all and (max-width: 1500px) {
	header nav {
    gap: 2rem;
	}

	header .contact-info {
		display: block;
	}

	header .contact-info span {
		display: block;
	}
}

@media all and (max-width: 1350px) {
	header .phone-toggle {
		display: block;
		width: 30px;
		height: 30px;
	}

	header .contact-info {
		display: none;
	}

	header .contact-info-mobile {
		display: block;
	}
}

@media all and (max-width: 1100px) {
	.button-container {
		display: none;
	}

	header .button-container-mobile {
		display: flex;
	}
}

@media all and (max-width: 1000px) {

	:root {
		--height-navigation: 250px;
		--font-size-heading-one: 3.6em;
		--font-size-heading-two: 2.0em;
		--font-size-heading-three: 1.2em;
		--font-size-heading-four: 1.2em;
		--font-size-heading-five: 1.0em;
		--font-size-paragraph-bg: 1.2em;
	}

	img.right,
	img.right.big,
	img.left,
	img.left.big {
		max-width: 415px;
	}

	img.left,
	img.left.big {
		margin: 0px 15px 15px 0px;
	}

	img.right,
	img.right.big {
		margin: 0px 0px 15px 15px;
	}

	#title h1.main-title {
    font-size: var(--font-size-heading-one)!important;
	}

}

@media all and (max-width: 768px) {

	:root {
		--width-logo: 150px;
		--height-logo: 110px;
		--height-navigation: 160px;
		--font-size-heading-one: 2.8em;
		--font-size-heading-two: 2.0em;
		--font-size-heading-three: 1.2em;
		--font-size-heading-four: 1.1em;
		--font-size-heading-five: 1.0em;
		--font-size-paragraph-bg: 1.1em;
		--padding-section-spacing: 4rem;
		--padding-gap: 2rem;
		}

	img.right,
	img.right.big,
	img.left,
	img.left.big {
		float: none!important;
		margin-left: 0;
		margin-bottom: 10px;
		max-width: 100%;
		padding: 0px;
	}

	table {
		max-width: 100%;
		width: 100%!important;
	}

	.content-container .wrapper {
		grid-template-columns: 1fr;
	}

	header {
		.logo  {
			top: -1.5rem;

			span.logo-title h1 {
				margin-top: 1.5rem;
			}

			span.logo-title h1 span {
				font-size: 3rem;
			}

			span.logo-title h1 span:nth-child(2) {
				font-size: 2.2rem;
			}

			span.logo-title h1 span.french-immersion-title {
				font-size: 3rem;
			}

			span.logo-title h1 span.french-immersion-subtitle {
				font-size: 1.8rem;
			}

		}
	}

	.footer-container {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.footer-column.logo-container.dsbn-logo {
		width: 100%;
	}

	.footer-column {
		width: 48%;
	}

	#content {
		gap: 4rem;
	}

	#content.with-sidebar {
		grid-template-columns: 1fr!important;
		grid-gap: 2rem;
	}

	#content #sidebar {
		order: 1;
    padding: 0;
	}

	#content.with-sidebar .left-content {
		order: 2;
	}

	#content.with-image {
		grid-template-columns: 1fr!important;
		grid-gap: 2rem;
	}

	#content #sidebar .sidebar-submenu {
		display: flex;
	}

	.mobile-panel {
		display: none;
		padding: 0 2.5rem;
		overflow: hidden;
	}

	.quick-link-container {
		grid-template-columns:1fr!important;
		margin: 0;
	}

	.quick-link-container a.quick-link:nth-child(7) {
		grid-column: 1;
	}

	.resource-link-container {
		grid-template-columns:1fr!important;
		margin: 0;
	}

	.month-grid {
		grid-template-columns:1fr 1fr!important;
	}


	#content #sidebar .sidebar-link {
		padding: 2rem 0;
	}

	.calendar_link {
		height: 500px;
	}

}

@media all and (max-width: 550px) {

	:root {
		--font-size-heading-one: 2.4em;
		--font-size-heading-two: 2.0em;
		--font-size-heading-three: 1.2em;
		--font-size-heading-four: 1.1em;
		--font-size-heading-five: 1.0em;
		--font-size-paragraph-bg: 1.1em;
		--padding-section-spacing: 4rem;
	}

	header {
		padding: 2rem 0;

		.wrapper {
			justify-content: space-between;
			height: 100%;
		}

		.logo  {
			position: static;
			top: 0;
			left: 0;
			max-height: 100%;

			a {
				max-height: 100%;
			}

			span.logo-title {
				display: none;
			}

			span.logo-icon {
				max-height: 100%;
				height: 80px;
			}

		}
	}

	#title h1.main-title {
		margin: 2rem 0;
	}

	.quick-link-container a.quick-link {
		padding: 3rem;
	}

	.quick-link-container a.quick-link img {
		height: 30px!important;
	}

	.footer-column.logo-container.dsbn-logo {
		width: 100%;
		text-align: center;
	}

	.footer-column {
		width: 100%;
		text-align: center;
	}

	.footer-column ul li {
		text-align: center;
	}

	.footer-logo,
	.footer-redefining-logo {
		margin: 0 auto;
	}

	#google-language-container {
		margin-top: 4rem;
	}

	header .contact-info-mobile {
		text-align: center;
		margin: 0 2rem 2rem 2rem;
	}

	.button-container-mobile {
		flex-wrap: wrap;
		gap: 2rem;
		justify-content: space-around;
		margin: 0 2rem 2rem 2rem;
	}

	.button-container-mobile .btn {
		width: 100%!important;
		text-align: center;
	}

	.family-of-schools .school {
		margin-bottom: 4rem;
	}

	.family-of-schools .school .logo {
		float: none;
	}

	.family-of-schools .school h3, .family-of-schools .school .info {
		margin-left: 0;
	}
}