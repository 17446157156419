@import '_reset.scss';
@import '_header.scss';
@import '_menu.scss';
@import '_cta.scss';
@import '_button.scss';
@import '_form.scss';
@import '_footer.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@300&display=swap');

@media ( prefers-reduced-motion: no-preference ) {
  html {
    scroll-behavior: smooth;
  }
}

body {
	background-color: var( --color-light );
	color: var( --color-dark );
	font-family: var( --font-family-primary );
	font-size: var( --font-size-base );
	margin: 0;
	padding: 0;
	text-align: left;
}

img {
	max-width: 100%;
	height: auto!important;
}

iframe {
	max-width: 100%;
	width: 100%;
	border: none;
	display: block;
}

a {
	color: var( --color-dark );
	text-decoration: none;
}

a:hover {
	color: var( --color-dark );
	text-decoration: underline;
}

a.banner {
	text-decoration: none!important;
}

p {
	line-height: 1.4em;
}

hr {
	border: 0;
	border-top: 1px solid #000000;
	width: 15%;
	margin: 4rem 0;
}

table {
	max-width: 100%;
}

table.hidden {
	display: none;
}

.heading, h1, h2, h3, h4, h5 {
	color: var(--color-heading);
	font-family: var(--font-family-heading);
	font-weight: bold;
	letter-spacing: 0px;
	line-height: 1.2em;
	margin: 0.75rem 0;
	text-transform: none;
}

h1,
.heading.one {
	font-size: var(--font-size-heading-one);
}

h2,
.heading.two {
	font-size: var(--font-size-heading-two);
}

h3,
.heading.three {
	font-size: var(--font-size-heading-three);
	font-weight: 700;
}

h4,
.heading.four {
	font-size: var(--font-size-heading-four);
}

h5,
.heading.five {
	font-size: var(--font-size-heading-five);
}

.heading a, h1 a, h2 a, h3 a, h4 a {
	color: inherit;
	text-decoration: none;
}

.heading em, h1 em, h2 em, h3 em, h4 em {
	font-style: normal;
	color: #c6c6c6;
}

.heading strong, h1 strong, h2 strong, h3 strong, h4 strong {
	font-weight: 800;
}

.heading.normal, h1.normal, h2.normal, h3.normal, h4.normal, p.normal {
	font-weight: 400;
}

.heading.bold, h1.bold, h2.bold, h3.bold, h4.bold, p.bold {
	font-weight: 700;
}

.heading.extra-bold, h1.extra-bold, h2.extra-bold, h3.extra-bold, h4.extra-bold, p.extra-bold {
	font-weight: 800;
}

.heading.margin-bg, h1.margin-bg, h2.margin-bg, h3.margin-bg, h4.margin-bg {
	margin: 4rem 0;
}

.italic-title {
	font-size: 120%;
	font-style: italic;
	margin: 0.75rem 0;
}

.subtitle {
	font-size: var(--font-size-paragraph-bg);
	margin: 0.75rem 0;
}

.subtitle.margin-bg {
	margin: 2rem 0;
}

.page-title {
	margin: 2rem 0;
}


/*--- MAIN WRAPPER ---*/


.wrapper {
	margin: 0px auto;
	padding: 0px 2rem;
	position: relative;
	text-align: left;
	max-width: var( --width-wrapper-main );
}

.wrapper.small {
	max-width: var( --width-wrapper-small );
}

.wrapper.medium {
	max-width: var( --width-wrapper-medium );
}

.wrapper.large {
	max-width: var( --width-wrapper-large );
}

.wrapper.center {
	text-align: center;
}

/*--- BASE CONTENT STYLES ---*/

#content {
	background-color: #FFFFFF;
	min-height: 300px;
}

#content.inside {
	background-color: #ffffff;
	background-position: top center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	padding-top: 100px;
	min-height: 500px;
}

.content-container {
	padding: var( --padding-gap ) 0;
	min-height: 50vh;
}

// .content-container .wrapper {
// 	display: grid;
// 	gap: var( --padding-gap );
// 	grid-template-columns: 1fr 300px;
// }

.page-section-container {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: var(--padding-section-spacing) 0;
	position: relative;
	text-align: center;
}

.page-section-container.no-padding-top {
	padding-top: 0;
}

.page-section-container.no-padding-bottom {
	padding-bottom: 0;
}

.page-section-container.padding-sm {
	--padding-section-spacing: 2rem;
}

.page-section-container.center h1,
.page-section-container.center h2,
.page-section-container.center h3,
.page-section-container.center h4,
.page-section-container.center h5,
.page-section-container.center .heading {
	margin-top: 0;
	text-align: center;
}

.page-section-container.center .wrapper{
	text-align: center;
}


.page-section-container.dark {
	background-color: #000000;
}

.page-section-container.white {
	background-color: #ffffff;
}

.page-section-container.grey {
	background-color: #F8F8F8;
}

.page-section-container.dark h1,
.page-section-container.dark h2,
.page-section-container.dark h3,
.page-section-container.dark h4,
.page-section-container.dark h5,
.page-section-container.dark .heading,
.page-section-container.dark p {
	color: #ffffff;
}

.page-section-container.dark hr {
	border-color: #ffffff;
}

.page-section-container.dark .subtitle {
	color: #ffffff;
}

#content.inside .page-section-container:first-of-type {
	padding-top: 0;
}

/*--- BREADCRUMB ---*/

.breadcrumb {
	text-transform: uppercase;
}

.breadcrumb a {
	color: var(--color-dark);
}

.breadcrumb span:not(:last-child):after {
	content: '>';
	padding: 0 10px;
}


/*--- ACCORDION ---*/

.accordion {
	background-color: #ccc;
	border-radius: var(--border-radius-small);
	color: var(--color-dark);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: bold;
	padding: 1.25rem 2.5rem;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: background-color 0.3s;
}

.accordion i {
	color: var(--color-dark);
}

.accordion.dark i {
	color: var(--color-light);
}

.accordion.dark {
	background-color: var(--color-primary);
	border-radius: var(--border-radius);
	color: var(--color-light);
	padding: 2rem 2.5rem 2rem 2.5rem;
}

.accordion.active, .accordion:hover {
	background-color: #ddd;
}

.accordion.dark.active, .accordion.dark:hover {
	background-color: var(--color-primary);
}

.panel {
	display: none;
	padding: 0 2.5rem;
	overflow: hidden;
}

/*--- NAVIGATION ---*/


div.navigation-container {
	text-align: center;
	display: inline-block;
	font-family: var(--font-family-primary);
	font-size: 110%;
	padding: 12px 5px;
	margin: 20px auto 20px auto;
	width: 100%;
	border-top: 1px solid #c5c5c5;
	border-bottom: 1px solid #c5c5c5;
}

div.navigation-container a {
	color: #000000;
	padding: 6px;
	text-decoration: none;
	font-size: 110%;
}

div.navigation-container span {
	color: #C50019;
	padding: 6px;
	font-size: 110%;
}

/*--- TABLE ---*/


#content table {
	width: 100%;
	padding: 0 10px;
	margin-bottom: 10px;
}

#content table.table-with-header tr:first-child {
	background-color: var(--color-primary)!important;
	color: #ffffff;
}

#content tr {
	background-color: #f3f3f3;
}

#content tr:nth-child(odd) {
	background-color: #f3f3f3;
}

#content th {
	background-color: inherit;
	padding: 15px 15px;
	font-size: 100%;
}

#content td {
	background-color: inherit;
	padding: 15px 15px;
	font-size: 100%;
}

#content td p {
	font-weight: normal;
	font-size: 100%;
	margin: 0;
}

span.input-error {
	color: #C20000;
	display: block;
	margin: 5px;
}

.clear {
	clear: both;
}

/*--- OTHER STYLES ---*/

#map {
	height: 350px;
}

img.right {
	float: right;
	margin-left: 15px;
	margin-bottom: 15px;
	max-width: 460px;
	height: auto!important;
}

img.left {
	float: left;
	margin-right: 15px;
	margin-bottom: 15px;
	max-width: 460px;
	height: auto!important;
}

img.right.big,
img.left.big {
	max-width: 560px;
}

a.video-link {
	display: block;
	position: relative;
}

.video-link:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	// background-image: url(../images/playbutton.png);
	background-size: 155px;
	background-position: center;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
	pointer-events: none;
}

.line-border {
	overflow: hidden;
	text-align: center;
}

.line-border:before,
.line-border:after {
	background-color: #e7e7e7;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.line-border:before {
	right: 0.5em;
	margin-left: -50%;
}

.line-border:after {
	left: 0.5em;
	margin-right: -50%;
}


/*--- FLEX ---*/

.flex {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}