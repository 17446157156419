.quick-link-container {
	display: grid;
	gap: 2rem;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 4rem 0;

	a.quick-link {
		align-items: center;
		border-radius: var(--border-radius);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 5rem;
		text-align: center;
		text-decoration: none!important;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;

		/* & = parent selector */

		&:hover {
			text-decoration: none;
			transform: scale(1.035); /* Increase the size by 5% on hover */
		}

		&:nth-child(7) {
			grid-column: 2;
		}

		&.dsbn {
			background-color: #ffffff;
		}

		&.school-cash {
			background-color:#0076CF;
		}

		&.report-absence {
			background-color:#D50037;
		}

		&.social {
			background-color:#0055B8;
		}

		&.facebook {
			background-color:#0055B8;
		}

		&.school-calendar {
			background-color:#FBB03B;
		}

		&.bell-times {
			background-color: #88C050;
		}

		&.instagram {
			background: linear-gradient(26deg, rgba(255,214,0,1) 0%, rgba(255,1,0,1) 50%, rgba(216,0,185,1) 100%);
		}

		&.newsletter-updates {
			background-color: #722289;
		}

		&.contact-us {
			background-color: #FF8C0E;
		}

		&.twitter {
			background-color: #231F20;
		}

		img {
			height: 40px!important;
			margin-bottom: 15px;
			width: auto!important;
		}

		span {
			color: var( --color-light );
			display: block;
			font-size: var( --font-size-h3 );
			font-weight: 600;
			letter-spacing: 0px;
			line-height: 1em;
		}
	}
}

#content {

	&.with-sidebar {
		display: grid;
		gap: 6rem;
		grid-template-columns: 1fr 300px;
	}

	&.with-image {
		display: grid;
		gap: 6rem;
		grid-template-columns: 1fr 1fr
	}

	p {
		// font-weight: bold;
		font-size: var(--font-size-paragraph-bg);
	}

	ul li {
		font-size: var(--font-size-paragraph-bg);
	}

	ol li {
		font-size: var(--font-size-paragraph-bg);
	}

	a:not(.btn) {
		color: var(--color-primary);
		text-decoration: underline;
	}

	a:not(.btn):hover {
		color: var(--color-secondary);
	}

	.right-content {

		&.map iframe {
			border-radius: var(--border-radius);
			max-width: 100%;
			width: 100%;
			border: none;
			display: block;
		}

		img {
			border-radius: var(--border-radius);
		}
	}

	#sidebar {
		background-color: var(--color-primary);
		border-radius: var(--border-radius);
		padding: 2rem 4rem;

		img {
			border-radius: var(--border-radius);
		}

		.sidebar-submenu {
			display: none;
		}

		.sidebar-link{
			color: var(--color-light);
			display: block;
			padding: 10px 0;
			text-decoration: none!important;
		}

		.sidebar-link:not(:last-child) {
			border-bottom: 1px solid var(--color-light);
		}
	}
}

.resource-link-container {
	display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; /* Adjust the gap as needed */

	a.resource-link {
		border: 4px solid var(--color-primary);
		border-radius: var(--border-radius);
		color: var(--color-dark);
		font-size: var(--font-size-paragraph-bg);
		// font-weight: bold;
		text-align: center;
		text-decoration: none!important;
		padding: 4rem;
	}

	a.resource-link:hover {
		background-color: var(--color-primary)!important;
		color: var(--color-light)!important;
		text-decoration: none!important;
	}
}

.month-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  gap: 10px;
}

.month {
  border: 2px solid var(--color-primary);
  border-radius: var(--border-radius);
	color: var(--color-dark);
	font-size: var(--font-size-paragraph-bg);
  text-align: center;
  padding: 3rem;
}

.month-link {
  color: var(--color-dark);
  text-decoration: none !important;
  display: block;
}

.month-link:hover {
  color: var(--color-primary);
}

a.month-link[href="#"],
a.month-link[href=""],
a.month-link:not([href]) {
  pointer-events: none;
}


a.month-link[href="#"]:hover,
a.month-link[href=""]:hover,
a.month-link:not([href]):hover {
	color: var(--color-dark);
}

.weekly-links {
  display: flex;
  flex-direction: column;
  gap: 5px;
	margin-top: 1rem;
}

.week-link {
  font-size: var(--font-size-paragraph-sm);
  color: var(--color-dark);
  text-decoration: none !important;
}

.week-link:hover {
  color: var(--color-primary);
}

a.week-link[href="#"],
a.week-link[href=""],
a.week-link:not([href]) {
  pointer-events: none;
}


a.week-link[href="#"]:hover,
a.week-link[href=""]:hover,
a.week-link:not([href]):hover {
	color: var(--color-dark);
}


#google-language-container {
	margin-top: 2rem;
}

/* Staff List */

#staff table {
	margin-top: 2rem;
	width: 100%;
}

#staff table tr {
	padding: 1rem 0;
}

#staff table tr td:nth-child(1) {
	width: 40%;
}

#staff table tr td:nth-child(2) {
	width: 40%;
}

#staff table tr td:nth-child(3) {
	width: 20%;
}

/* Family of Schools */

.family-of-schools .school {
	margin-bottom: 3rem;
}

.family-of-schools acronym {
	font-weight: bold;
	border: 0px none;
	text-decoration: none;
}

.family-of-schools .school .info {
	margin-left: 5px;
	color: #000;
}

.family-of-schools .school .info .website {
	word-break:break-all;
}

.family-of-schools .school .logo {
	float: left;
}

.family-of-schools .school h3,
.family-of-schools .school .info {
	margin-left: 100px;
}