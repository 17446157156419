header {
	background-color: var( --color-primary );
	color: var( --color-light );
	height: var( --height-header );
	padding: 4rem 0;
	position: relative;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	}

	.logo {
		position: absolute;
		top: -1rem;
		left: 20px;;
		z-index: 9999;

		a {
			display: flex;
		}

		a:hover {
			text-decoration: none;
		}

		span.logo-icon {
			height: var( --height-logo );
			max-width: 210px;
		}

		span.logo-icon img {
			display: block;
			max-height: 100%;
		}

		span.logo-title {
			padding-left: 7px;
			padding-top: 3px;
		}

		span.logo-title h1 {
			color: #ffffff;
			font-family: 'Rokkitt', serif;
			text-decoration: none;
			text-transform: uppercase;
		}

		span.logo-title h1 span {
			display: block;
			line-height: 1em;
			font-size: 3.5rem;
		}

		span.logo-title h1 span.french-immersion-title {
			font-size: 3.8rem;
		}

		span.logo-title h1 span:nth-child(2) {
			font-size: 3rem;
			line-height: .9em;
			padding-left: 14px;
		}

		span.logo-title h1 span.french-immersion-subtitle {
			font-size: 2rem;
			line-height: 1.2em;
		}

	}

	&.active .logo  {
		top: -1.5rem;
	}

	&.active .logo span.logo-icon {
		height: 80px;
	}

	nav {
		align-items: center;
		display: flex;
		gap: 4rem;
		justify-content: flex-end;
		position: relative;
		z-index: 9999;
	}

	#menu {
		display: none;
	}

	.menu-toggle {
		display: block;
	}

	.phone-toggle {
		color: var(--color-light);
		display: none;
	}

	.phone-toggle svg {
		color: var(--color-light);
		width: 30px;
		height: 30px;
	}

	.phone-toggle a {
		display: flex;
		width: 30px;
	}

	.contact-info {
		font-size: 90%;
		display: flex;
		gap: 1rem;
	}

	.contact-info a {
		color: var(--color-light);
	}

	.button-container .btn {
		border-radius: 5px;
		font-size: 80%;
		margin-right: 10px;
		padding: 0.75rem 2.0rem;
		text-transform: uppercase;
	}

	.button-container-mobile {
		display: none;
		gap: 2rem;
		justify-content: space-around;
		margin: 0 2rem 2rem 2rem;
	}

	.button-container-mobile .btn {
		width: 50%;
		text-align: center;
	}

	.contact-info-mobile {
		display: none;
		text-align: right;
		margin: 0 2rem 2rem 0;
	}
}

body.WHM span.logo-icon {
	max-width: 165px;
}

#title {
	align-items: center;
	display: flex;
	min-height: var( --height-navigation );
	justify-content: center;
	text-align: center;

	.wrapper {
		text-align: center;
		max-width: 870px;
	}

	&.home {
		height: 80vh;
		position: relative;
	}

	&.home .title-content {
		padding: 6rem 0;
	}

	.slideshow .slide.title {
		background:  linear-gradient(
			var( --color-overlay ),
			var( --color-overlay )
		),
		url( ../images/banner-background.png );
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
	}

	.slideshow .title-content {
		display: block;
		width: 1000px;
		max-width: 100%;
	}

	&.home .title-content {
		padding: 6rem 2rem;
	}

	&.inside {
		background:  linear-gradient(
			var( --color-overlay ),
			var( --color-overlay )
		),
		url( ../images/banner-background.png );
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	// &.inside .title-content {
	// 	// padding: 8rem 0;
	// }

	h1.main-title {
		color: var( --color-light );
		font-size: var( --font-size-heading-one );
		font-family: var( --font-family-secondary );
		font-weight: 800;
		letter-spacing: 2px;
		margin: 0;
		text-align: center;
	}

	.title-content h2 {
		color: var( --color-light );
		font-size: calc(var(--font-size-heading-two) * 0.8);
		text-align: center;
		margin: 2rem 0;
	}

	.title-content .btn {
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0.75rem 2rem;
    font-size: 90%;
		margin: 2rem 0 0 0;
	}
}

#back-top {
	background-image: url( ../images/top.png );
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0.5);
	background-size: 20px auto;
	border-radius: var(--border-radius-small);
	bottom: 10px;
	opacity: 0;
	position: fixed;
	right: 10px;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	visibility: hidden;
	width: 45px;
	z-index: 800;
}

#back-top:hover {
	background-color: var(--color-primary);
}

#back-top a {
	display: block;
	height: 45px;
	text-align: left;
	text-indent: -9999px;
	width: 45px;
}

#back-top.active {
	opacity: 1;
	visibility: visible;
}